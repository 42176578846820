"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachPage = void 0;
var attach_manual_1 = require("./attach_manual");
var utils_1 = require("./utils");
function attachTags() {
    document.querySelectorAll("data.paq.event").forEach(function (item) {
        var _a;
        var values = (_a = item.getAttribute("value")) === null || _a === void 0 ? void 0 : _a.split(";");
        if (values)
            utils_1.trackEvent.apply(void 0, values);
        item.remove();
    });
}
function attachAttributes() {
    document.querySelectorAll("[data-paq-click]").forEach(function (item) {
        var _a;
        var elem = item;
        var data = (_a = elem.dataset.paqClick) === null || _a === void 0 ? void 0 : _a.split(";");
        if (!data)
            return;
        elem.addEventListener("mouseup", function () { return utils_1.trackEvent.apply(void 0, data); });
        elem.removeAttribute("data-paq-click");
    });
    document.querySelectorAll("[data-paq-check]").forEach(function (item) {
        var _a;
        var elem = item;
        var data = (_a = elem.dataset.paqCheck) === null || _a === void 0 ? void 0 : _a.split(";");
        if (!data)
            return;
        elem.addEventListener("change", function (event) {
            var target = event.target;
            if (!target)
                return;
            var newData = __spreadArray([], data, true);
            if (newData.length >= 2)
                newData[1] += "_" + (target.checked ? "check" : "uncheck");
            utils_1.trackEvent.apply(void 0, newData);
        });
        elem.removeAttribute("data-paq-check");
    });
}
function attachPage() {
    attachTags();
    attachAttributes();
    (0, attach_manual_1.attachManual)();
}
exports.attachPage = attachPage;
