"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachManual = void 0;
var helpers_1 = require("../helpers");
var utils_1 = require("./utils");
function manualAttachForgetPage() {
    var _a;
    (_a = document
        .querySelector('input[name="email"]')) === null || _a === void 0 ? void 0 : _a.addEventListener("click", function () { return (0, utils_1.trackEvent)("IDP_forget", "email_click"); });
}
function attachManual() {
    var currentPage = (0, helpers_1.getPageId)();
    switch (currentPage) {
        case "forget_password":
            return manualAttachForgetPage();
    }
}
exports.attachManual = attachManual;
