"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleVisibility = exports.initPasswordVisibilityToggles = void 0;
function initPasswordVisibilityToggles() {
    document.querySelectorAll(".password-visibility-toggle").forEach(function (component) {
        var toggle = component.querySelector("a.toggle");
        if (!toggle)
            return;
        toggle.addEventListener("click", function (event) {
            event.preventDefault();
            toggleVisibility(component, toggle);
        });
    });
}
exports.initPasswordVisibilityToggles = initPasswordVisibilityToggles;
function toggleVisibility(component, toggle) {
    var _a, _b, _c, _d;
    var shown = !toggle.dataset.shown ? "false" : toggle.dataset.shown === "true";
    if (shown) {
        toggle.innerHTML = '<i class="fas fa-fw fa-eye-slash"></i>';
        (_b = (_a = component.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('input[type="password"]')) === null || _b === void 0 ? void 0 : _b.setAttribute("type", "text");
    }
    else {
        toggle.innerHTML = '<i class="fas fa-fw fa-eye"></i>';
        (_d = (_c = component.parentElement) === null || _c === void 0 ? void 0 : _c.querySelector('input[type="text"]')) === null || _d === void 0 ? void 0 : _d.setAttribute("type", "password");
    }
    toggle.dataset.shown = shown ? "false" : "true";
}
exports.toggleVisibility = toggleVisibility;
