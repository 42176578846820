import { library, dom } from "@fortawesome/fontawesome-svg-core"

import {
  faExclamationTriangle,
  faCheckCircle,
  faInfoCircle,
  faArrowLeft,
  faArrowRight,
  faEye,
  faEyeSlash,
  faTimes,
  faUser,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons"

import {
  faEnvelope,
  faClock,
} from "@fortawesome/free-regular-svg-icons"

export function initFontAwesome() {
  library.add(
    faExclamationTriangle,
    faCheckCircle,
    faInfoCircle,
    faArrowLeft,
    faArrowRight,
    faEye,
    faEyeSlash,
    faTimes,
    faUser,
    faCircleNotch,

    faEnvelope,
    faClock,
  )

  dom.watch()
}
