"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackPage = exports.initTracker = void 0;
var attach_1 = require("./attach");
var api_1 = require("../api");
var utils_1 = require("./utils");
var helpers_1 = require("../helpers");
function initTracker() {
    var _a;
    var endpoint = api_1.idpjsdata.tracker.base_url;
    window._paq = __spreadArray(__spreadArray([], (window._paq || []), true), [
        ["enableLinkTracking"],
        ["setTrackerUrl", endpoint + "/js/"],
        ["setSiteId", api_1.idpjsdata.tracker.site_id],
    ], false);
    var scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.src = endpoint + "/js/";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    (_a = firstScriptTag === null || firstScriptTag === void 0 ? void 0 : firstScriptTag.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(scriptTag, firstScriptTag);
}
exports.initTracker = initTracker;
function trackPage() {
    var pageName = (0, helpers_1.getPageName)();
    if (pageName)
        (0, utils_1.track)("setDocumentTitle", pageName);
    (0, utils_1.track)("trackPageView");
    (0, attach_1.attachPage)();
}
exports.trackPage = trackPage;
