"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("../vendor/mdbootstrap");
var fontawesome_1 = require("../vendor/fontawesome");
var tracker_1 = require("./tracker");
var hooks_1 = require("./hooks");
var helpers_1 = require("./helpers");
var password_visibility_toggle_1 = require("./components/password_visibility_toggle");
require("../../scss/common.scss");
(0, hooks_1.initInputLabels)();
(0, fontawesome_1.initFontAwesome)();
(0, tracker_1.initTracker)();
(0, helpers_1.domReady)(function () {
    (0, hooks_1.addIsInvalidToFields)();
    (0, hooks_1.initBtnOnce)();
    (0, password_visibility_toggle_1.initPasswordVisibilityToggles)();
    (0, tracker_1.trackPage)();
});
