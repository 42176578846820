"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initBtnOnce = exports.initInputLabels = exports.addIsInvalidToFields = void 0;
var helpers_1 = require("./helpers");
var Input = require("../vendor/mdbootstrap").Input;
function addIsInvalidToFields() {
    document.querySelectorAll(".idp-form-is-invalid").forEach(function (item) {
        item.querySelectorAll("input").forEach(function (field) { return field.classList.add("is-invalid"); });
        item.classList.remove("idp-form-is-invalid");
    });
}
exports.addIsInvalidToFields = addIsInvalidToFields;
function initInputLabels() {
    var updateLabels = function () {
        document.querySelectorAll(".form-outline").forEach(function (item) {
            var input = new Input(item);
            input.update();
        });
    };
    (0, helpers_1.domReady)(function () {
        setTimeout(updateLabels, 1000);
    });
    window.addEventListener("pageshow", updateLabels);
}
exports.initInputLabels = initInputLabels;
function initBtnOnce() {
    var handler = function (button) {
        if (!(button instanceof HTMLButtonElement))
            return;
        if (button.getAttribute("type") == "submit") {
            var form = button.form;
            if (form) {
                form.addEventListener("submit", function () {
                    button.setAttribute("disabled", "disabled");
                    button.innerHTML = '<i class="fas fa-circle-notch fa-spin"></fa>';
                });
                return;
            }
        }
        button.addEventListener("click", function () {
            button.setAttribute("disabled", "disabled");
            button.innerHTML = '<i class="fas fa-circle-notch fa-spin"></fa>';
        });
    };
    document.querySelectorAll(".btn-once").forEach(handler);
}
exports.initBtnOnce = initBtnOnce;
