"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCooldownTimer = exports.getPageName = exports.getPageId = exports.getDataElementValue = exports.domReady = void 0;
function domReady(callbackFn) {
    if (document.readyState === "complete") {
        callbackFn();
    }
    else {
        document.addEventListener("DOMContentLoaded", callbackFn);
    }
}
exports.domReady = domReady;
function getDataElementValue(classes) {
    var selector = __spreadArray(["data"], classes, true).join(".");
    var element = document.querySelector(selector);
    if (!element)
        return undefined;
    if (!(element instanceof HTMLDataElement))
        return undefined;
    return element.value;
}
exports.getDataElementValue = getDataElementValue;
function getPageId() {
    return getDataElementValue(["idp", "page"]) || null;
}
exports.getPageId = getPageId;
function getPageName() {
    var page = getPageId();
    if (!page)
        return null;
    if (page === "login") {
        var wizard = getDataElementValue(["idp", "login", "wizard"]);
        if (wizard)
            page += "/".concat(wizard);
    }
    else if (page === "error") {
        var code = getDataElementValue(["idp", "error", "code"]);
        if (code)
            page += "/".concat(code);
    }
    return page;
}
exports.getPageName = getPageName;
function formatCooldownTimer(seconds) {
    return (Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0") +
        " : " +
        (seconds % 60).toString().padStart(2, "0"));
}
exports.formatCooldownTimer = formatCooldownTimer;
